//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "HomeView",
  data() {
    return {
      active: 0,
      list: {
        data: [{
          lable: '',
          achievement: 0,
          fish: 0
        }],
        total: {
          lable: '总计',
          achievement: 0,
          fish: 0
        }
      },
      lastYearList: {
        data: [{
          lable: '',
          achievement: 0,
          fish: 0
        }],
        total: {
          lable: '总计',
          achievement: 0,
          fish: 0
        }
      }
    };
  },
  mounted() {

    window.document.title = "历史业绩";
    this.getList();
    this.getLastYearList()

  },
  methods: {
    getList() {
      this.$toast.loading({
                message: '',
                forbidClick: true,
                duration: 0
            });
      var pdata = [{
        'PARAMETER': 'Z0CALDAY_MVSM_003',
        'SIGN': 'I',
        'OPTION': 'EQ',
        'LOW': this.$moment().endOf("month").format('yyyyMMDD'),
        'HIGH': '',
        "LOW_FLAG": "0"

      }, {
        'PARAMETER': 'Z0EMPLOYEE_CMOO_001',
        'SIGN': 'I',
        'OPTION': 'EQ',
          'LOW': this.$memberInfo.hhrEmpid,
       // 'LOW': '10000239',
        "LOW_FLAG": "1",
        'HIGH': ''
      }]
      this.list.data = []
      this.axios
        .post(
          "/" + this.$ajaxPrefix.consumer +
          "/bw/BwApi/dd_staffSaleListData",
          pdata
        )
        .then((res) => {
          this.$toast.clear();
          console.log(res.data);
          if (res && res.data && res.data.data && res.data.data.length > 0) {
            const { total, data } = this.rebuildData(res.data.data, this.$moment().format('yyyy'));
            this.list.total = total
            this.list.data = data;
          }

        });
    },
    getLastYearList() {
      var pdata = [{
        'PARAMETER': 'Z0CALDAY_MVSM_003',
        'SIGN': 'I',
        'OPTION': 'EQ',
        'LOW': this.$moment().subtract(1, 'year').endOf("year").format('yyyyMMDD'),
        'HIGH': '',
        "LOW_FLAG": "0"

      }, {
        'PARAMETER': 'Z0EMPLOYEE_CMOO_001',
        'SIGN': 'I',
        'OPTION': 'EQ',
          'LOW': this.$memberInfo.hhrEmpid,
       // 'LOW': '10000239',
        "LOW_FLAG": "1",
        'HIGH': ''
      }]
      this.lastYearList.data = []
      this.axios
        .post(
          "/" + this.$ajaxPrefix.consumer +
          "/bw/BwApi/dd_staffSaleListData",
          pdata
        )
        .then((res) => {
          console.log(res.data);
          if (res && res.data && res.data.data && res.data.data.length > 0) {

            const { total, data } = this.rebuildData(res.data.data, this.$moment().subtract(1, 'year').format('yyyy'));
            this.lastYearList.total = total
            this.lastYearList.data = data;
          }

        });
    },

    rebuildData(data, year) {

      let dataList = {};
      for (let i = 0; i < 12; i++) {
        let j = i + 1
        let month = (j < 10) ? '0' + j : j;
        let key = year + month
        dataList[key] = {
          lable: j + '月',
          achievement: 0,
          fish: 0
        }

      }
      let fishNum = 0;
      for (let i = 0; i < data.length; i++) {
        let sum_fish = Number(data[i].sum_fish);
        dataList[data[i].CALDAY__0CALMONTH].fish = sum_fish;
        fishNum += sum_fish;
        let sumAchievement = 0
        let limit = Number(data[i].fish_limit);
        sumAchievement = Number(data[i].sum_achievement) + (sum_fish) * 50;
        if (fishNum > limit) {
          let overNUmber=fishNum-limit
          if((sum_fish-overNUmber)>0){
            sumAchievement = sumAchievement - overNUmber * 50
          }else{
            sumAchievement = sumAchievement - (sum_fish) * 50;
          }
      
        }
        dataList[data[i].CALDAY__0CALMONTH].achievement = Math.floor(sumAchievement)
      }
      let finalData = [];
      let sumData = {
        achievement: 0,
        fish: 0
      }
      for (const key in dataList) {
        sumData.achievement += dataList[key].achievement
        sumData.fish += dataList[key].fish
        finalData.push(dataList[key])

      }

      return {
        total: {
          lable: '总计',
          achievement: Math.floor(sumData.achievement),
          fish: sumData.fish
        },
        data: finalData
      }



    },
    formatDate(str) {
      return this.$moment(str).format('YYYY-MM-DD');
    }
  },
};
